<template>
    <div class="currency">
        <solution-select class="solutionWrap" v-if="showSolution" @close='close' @getData="getData"></solution-select>
         <div style="position:relative">
          <img class="bannerImg" src="../assets/images/productBannar.png" alt="">
        <div class="banner">
            <p class="title">智算中心是新型生产力</p>
            <p>正在赋能千行百业</p>
        </div>
      </div>
        <!-- <div class="panorama">
            <p class="panoramaTitle">{{pages.name}}解决方案</p>
            <img style="margin-top: 0.5rem;" class="bannerImg" src="../assets/images/solution/banner.png" alt="">
        </div>
        <p class="panoramaTitle">方案简介</p>
        <div class="page_wrap" style="margin:0.5rem auto;">
            <div class="page_content" style="maxWidth:15rem">
                <div v-for="(item, index) in pages.photoList" :key="index">
                    <div class="newsList-item news-flex">
                        <div class="newsList-item-left background" :style="{ 'backgroundImage': `url(${item.src})`}">
                        </div>
                        <div class="newsList-item-right">
                            <div class="newsItem-content">
                                {{ item.content }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="currencyAdvantage">
            <p class="title">方案优势</p>
            <div class="page_flex" style="width:100%">
                <div class="left">
                    <p class="titleChildren">专业团队为您打造专业方案</p>
                    <div class="text">{{pages.advantage.left}}</div>
                </div>
                <div class="right">
                    <p class="titleChildren">优异的资源满足您的需求</p>
                    <div class="text">{{pages.advantage.right}}</div>
                </div>
            </div>
        </div> -->
        <div class="panorama">
              <div class="functions">
                  <div class="content" v-for="(item,i) of pages.functions" :key="i" :style="{ 'backgroundImage': `url(${item.url})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover'}">
                      <p>{{item.title}}</p>
                      <p>{{item.content}}</p>
                  </div>
              </div>
              <div class="cloudPlatform">
                  <div class="title">{{pages.cloudPlatform.title}}</div>
                  <div class="content">
                   <div class="largeImage">
                       <img :src="pages.cloudPlatform.url" alt="">
                   </div>
                   <div class="subcontent">{{pages.cloudPlatform.content}}</div>
                  </div>
              </div>
                  <!-- <div  class="servicesArea" :style="{ 'backgroundImage': `url(${pages.servicesArea.url})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover','height':'10rem'}">
                 </div> -->
              <div class="industries">
                  <div class="content">
                   <div class="largeImage">
                       <img :src="pages.industries.url" alt="">
                   </div>
                   <div>
                       <div class="title">{{pages.industries.title}}</div>
                       <div class="subcontent">{{pages.industries.content}}</div>
                   </div>
                  </div>
              </div>
        </div>
        <div class="panorama panpramaExpand">
               <div class="storage">
                    <div class="title">{{pages.storage.title}}</div>
                  <div class="content">
                     <div>
                       <div class="subcontent">{{pages.storage.content}}</div>
                    </div>
                   <div class="largeImage">
                       <img :src="pages.storage.url" alt="">
                   </div>
                </div>  
                <div class="cards">
                       <div class="card" v-for="(item,i) of pages.storage.subcontent" :key="i">
                           <img :src="item.url" alt="">
                           <p class="cardTitle">{{item.title}}</p>
                           <p class="cardContent">{{item.content}}</p>
                       </div>
                   </div>
              </div>
            
        </div>
        <div class="panorama panoramaEnlarge">
              <div class="title">{{pages.dataMark.title}}</div>
              <div class="dataMark">
                  <div class="content">
                       <div :style="{ 'backgroundImage': `url(${pages.dataMark.url})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover','height':'5rem'}">
                    <!-- <div class="largeImage">
                      <img :src="pages.dataMark.url" alt=""> -->
                  </div>
                  <div :style="{ 'backgroundImage': `url(${pages.dataMark.rightUrl})` ,'background-repeat':'no-repeat','background-position':'center','background-size':'cover','height':'5rem','padding-top':'1rem','box-sizing':'border-box'}">
                       <div class="subcontent">{{pages.dataMark.content}}</div>
                       <div class="titles">
                           <div class="subtitle" v-for="(item,i) of pages.dataMark.subcontent" :key="i">
                               <span>{{item.icon}}</span>{{item.content}}
                           </div>
                       </div>
                   </div>
                  </div>
              </div>
        </div>
<!--        <div class="customerCase">-->
<!--            <p class="panoramaTitle">客户案例</p>-->
<!--            <div class="page_wrap" style="margin:0.5rem auto;">-->
<!--                <div class="page_content" style="maxWidth:15rem">-->
<!--                    <div v-for="(item, index) in pages.caseList" :key="index">-->
<!--                        <div class="newsList-item news-flex">-->
<!--                            <div class="newsList-item-left background" :style="{ 'backgroundImage': `url(${item.src})`}">-->
<!--                            </div>-->
<!--                            <div class="newsList-item-right">-->
<!--                                <div class="newsItem-title news-flex">-->
<!--                                    <span>{{ item.name }}</span>-->
<!--                                </div>-->
<!--                                <div class="newsItem-content">-->
<!--                                    {{ item.content }}<br>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <div class="partner">
            <p class="panoramaTitle">合作伙伴</p>
            <p class="partnerTitle">我们将与众多同行者一起共建人工智能产业生态</p>
            <div class="partnerList">
                <img v-for="(item, index) in imglist" :key="index"  :src="item" style="margin: 0 0.5rem;">
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import solutionSelect from '../../src/views/solutionSelect.vue'
export default {
    name: 'solution',
    components:{solutionSelect},
    watch: {
        '$store.state.pages'(val) {
            console.log(2)
            this.pages = val
        }
    },
    data () {
        return {
            pages:{
             functions:[
                 {
                     title:"AI算力规模",
                     content:"每秒80亿亿次",
                     url:require('@/assets/images/currency_p1_01.png')
                 },
                  {
                     title:"1小时可完成",
                     content:"100亿张图像处理",
                     url:require('@/assets/images/currency_p1_02.png')
                 },
                  {
                     title:"自动驾驶AI数据处理",
                     content:"1万公里",
                     url:require('@/assets/images/currency_p1_03.png')
                 },
                   {
                     title:"语音翻译",
                     content:"300万小时",
                     url:require('@/assets/images/currency_p1_04.png')
                 },
             ],
             cloudPlatform:{
                 title:"智算云平台",
                 content:'南京智能计算中心由南京市麒麟科技创新园携手浪潮、寒武纪共同打造，采用领先的寒武纪思元270和思元290智能芯片及加速卡。通过算力的生产、聚合、调度和释放四大关键作业环节，提供人工智能应用所需的强大算力服务。已投入运营的Al算力规模,1小时可完成100亿张图像识别、300万小时语音翻译或1万公里的自动驾驶Al数据处理任务。',
                 url:require('@/assets/images/currency_p2.png')
             },
             servicesArea:{
                 url:require('@/assets/images/currency_p3.png')
             },
              industries:{
                 title:"融合海量算法，赋能万千行业",
                 content:'目前平台主要用户区域分布:华东、华北、广东、香港、新疆、四川等区域。主要用户行业占比依次是:大数掘丁业检测机器视觉、生物制药、气象预测、自动驾驶等行业。',
                 url:require('@/assets/images/currency_p4.png')
             },
               storage:{
                 title:"智能云储存",
                 content:'智算中心作为智慧时代最为重要的基础设施之一，承担着智慧时代下数据处理的重任。因此，数据处理性能决定着智算中心生产力效率的关键所在，是智算中心未来发展的核心。南京智算中心基于浪潮智能引擎实现超强性能，升级自动感知风险、六重保护的安全稳定、EB级优化利用率的超大容量、基于iSCMI云对接技术的易用云化、融合AlOps智能运维的易管理等等，从而为智算中心带来“安全可靠、经济高效、易用易管”的存储平台。',
                 url:require('@/assets/images/currency_p5.png'),
                 subcontent:[
                     {
                         title:"安全、稳定、可靠",
                         content:"智算中心智慧云存储采用IDC容灾备份，高于业界同类水平，存储的数据可靠性达到了99.999999999%，保证数据永不丢失。通过我们自有的数据传输技术，实现数据在多个机房读写的一致性，大大减少了用户开发成本。",
                         url:require('@/assets/images/currency_p5_01.png'),
                     },
                     {
                         title:"速度快、扩展容易",
                         content:"智算中心云存储系统支持弹性扩容，用户无需担心未来存储空间不足。我们的存储节点采用了多个运营商网络接入，覆盖电信、联通、移动、教育网等各大网络,真正做到了—次上传各个运营商内都能快速访问。",
                         url:require('@/assets/images/currency_p5_02.png'),
                     },
                     {
                         title:"按需付费、享用智算中心架构",
                         content:"云存储构建于南京智算中心自身产品和网络服务的基础设施之上，智算中心云平台的架构能够自如应对每日千万级用户的访问。用户仅需按实际用量付费就能享用智算中心的基础设施。",
                         url:require('@/assets/images/currency_p5_03.png'),
                     },
                     {
                         title:"丰富的API、SDK",
                         content:"智算中心云存储为开发者提供了功能丰富的API、简单易用并且兼容多平台的SDK、管理工具，开发者使用SDK可以快速接入我们的存储服务。",
                         url:require('@/assets/images/currency_p5_04.png'),
                     },
                     ]
               },
               dataMark:{
                   title:"数据标注与清洗",
                   content:"适用于大规模的图像、视频、语音、文本以及其他特殊数据的数据清洗、评估、提取以及殊信息标注，专业的标注团队高效、稳定提供数据标注服务，结合高质量的内容审核解决方案务，助力客户高效展开算法模型训练与机器学习，快速提高Al领域竞争力。",
                   url:require('@/assets/images/currency_p6_01.png'),
                   rightUrl:require('@/assets/images/currency_p6_02.png'),
                   subcontent:[
                       {
                          icon:'◆',content:'图像语义分割'
                       },
                       {
                          icon:'◆',content:'文字标注'
                       },
                        {
                          icon:'◆',content:'图片/视频分类'
                       },
                        {
                          icon:'◆',content:'图视频轨迹等行业标注'
                       },
                        {
                          icon:'◆',content:'交通信号/车辆框选'
                       },
                        {
                          icon:'◆',content:'人脸采集标注'
                       },
                   ]
               }
            },
            // pages: {
            //     name: '智算云平台',
            //     photoList: [{name: '智算云平台',
            //         src: require('@/assets/images/industry2.png'),
            //         content: '南京智能计算中心由南京市麒麟科技创新园携手浪潮、寒武纪共同打造，采用领先的寒武纪思元270和思元290智能芯片及加速卡。通过算力的生产、聚合、调度和释放四大关键作业环节，提供人工智能应用所需的强大算力服务。已投入运营的AI算力规模，1小时可完成100亿张图像识别、300万小时语音翻译或1万公里的自动驾驶AI数据处理任务。',
            //     }],
            //     caseList: [{name: '工业视觉—智能矿石检测',
            //         src: require('@/assets/images/solution/case.png'),
            //         content: '传统的生产流水线上产品下线后大多有一个目检环节，即通过人眼检查发现产品表面的缺陷和瑕疵，人工目检效率低、容易漏检，并且人工成本也越来越高，智能云边一体开放平台通过视觉分析算法模型极大提高了检测效率。'
            //     }],
            //     advantage: {
            //         left: '完全由南京麒麟管委会投资建设，全系列使用浪潮智能计算优化服务器及国产AI 计算卡，具有大规模、自主可控的优势。是“新基建”的重要组成部分，也是为全社会提供算力的重要公共服务设施。',
            //         right: '机房已通过等保三级认证，能够在统一安全策略下防护系统免受来自外部有组织的团体、拥有较为丰富资源的威胁源发起的恶意攻击、较为严重的自然灾难、以及其他相当危害程度的威胁所造成的主要资源损害，能够发现安全漏洞和安全事件，在系统遭到损害后，能够较快恢复绝大部分功能。'
            //     },
            // },
            showSolution: true,
            imglist:[
                require('@/assets/images/partner/1.png'),require('@/assets/images/partner/2.png'),require('@/assets/images/partner/3.png'),
                require('@/assets/images/partner/4.png'),require('@/assets/images/partner/5.png'),require('@/assets/images/partner/6.png'),
                require('@/assets/images/partner/7.png'),require('@/assets/images/partner/8.png'),require('@/assets/images/partner/9.png'),
                require('@/assets/images/partner/10.png'),require('@/assets/images/partner/11.png'),require('@/assets/images/partner/12.png'),
                require('@/assets/images/partner/13.png'),require('@/assets/images/partner/14.png'),require('@/assets/images/partner/15.png'),
                require('@/assets/images/partner/16.png'),require('@/assets/images/partner/17.png'),require('@/assets/images/partner/18.png'),
                require('@/assets/images/partner/19.png'),require('@/assets/images/partner/20.png'),require('@/assets/images/partner/21.png'),
            ],
        }
    },
    created () {
        document.body.scrollTop = document.documentElement.scrollTop = 0
    },
    methods: {
        toRoute (val) {
            this.$router.push(val)
        },
        close(){
            this.showSolution = false
        },
        getData(data) {
            this.pages = data.pages
        }
    }
}
</script>
<style lang="scss">
  .currency{
    .banner{
        left:10%;
        transform: translateY(-60%);
        p:nth-child(1){
         display: inline-block;
         line-height: 0.5rem;
         font-size: 0.4rem;
        }
       p:nth-child(2){
        font-size: 0.4rem;
        margin-top: 0.2rem;
        display: block;
        border-bottom: none;
       }
      }
    }
</style>
<style lang="scss" scoped>
.currencyAdvantage {
    width: 100%;
    height: 3.6rem;
    background: #F7F7F7;
    .title {
        color: #333333;
        font-size: 28px;
        padding: 0.2rem 0;
    }
    .titleChildren {
        color: #333333;
        font-size: 18px;
        padding: 0.2rem 0;
        font-weight: 600;
    }
    .left,.right {
        display: inline-block;
        width: 38%;
        height: 2rem;
        background: #FFFFFF;
        box-shadow: 0rem 0.03rem 0.1rem 0rem rgba(204, 204, 204, 0.46);
        border-radius: 0.03rem;
        margin: 0.2rem auto;
        .text {
            padding: 0.2rem 1rem;
            text-align: left;
            color: #666666;
        }
    }
    .right {
        margin-left: 4%;
    }
}
.panpramaExpand{
    // padding-top: 0.6rem;
    margin-top: 0.6rem;
    padding-bottom: 0.6rem;
    background-color: #F5F5F5;
    width: 100%!important;
    .storage{
        width: 70%!important;
        margin: auto;
        padding-top: 0.6rem;
    }
}
.panoramaEnlarge{
    width: 100%!important;
    background-color: #FFFFFF;
    .title{
        text-align: center;
    }
    .titles{
        margin-top: 0.4rem;
        display: flex;
        justify-content: space-between;
         flex-wrap: wrap;
         padding-left: 0.4rem;
        .subtitle{
           width: 40%;
           font-size: 0.2rem;
           margin-bottom: 0.2rem;
           span{
               color:#196ECE ;
               margin-right: 0.1rem;
               
           }
        }
    }
    // .subcontent{
    //     padding: 0.6rem ;
    // }
    
}
.background-bottom{
    position:absolute;
    width: 100%;
    bottom:0;
    left:0;
    background: #ffffff7c;
    height:0.5rem;
}
.background-bottom-content{
    width: 70%;
    height: 0.5rem;
    line-height: 0.5rem;
    display: flex;
    justify-content: space-between;
    margin:0 auto;
    &-item{
        width: 0.8rem;
        height: 0.5rem;
        color:#606266;
        cursor: pointer;
        font-size: 0.14rem;
    }
    &-item:hover{
        color:#0061E3;
        border-bottom:1px solid #0061E3;
    }
}
.currency {
    @keyframes partnerAnima
    {
        0%   {right: 0}
        50%  {right: 30rem}
        100%  {right: 60rem}
    }
    .partnerList {
        position: relative;
        animation: partnerAnima 100s linear 1 alternate;
    }
    width: 100%;
    height: 100%;
    // text-align: center;
    .detail {
        background: #F7F7F7;
        width: 80%;
        margin: 0.5rem auto;
        padding: 0.2rem;
        .text {
            text-align: left;
            width: 60%;
            margin: 0.2rem auto;
            color: #333333;
            font-size: 0.14rem;
        }
    }
    .partner {
        overflow: hidden;
        width: 80%;
        margin: 0 auto;
        text-align: center;
        .partnerList {
            height: 1.2rem;
            width: 61rem;
            img {
                height: 1.2rem;
                float: left;
            }
        }
        .partnerTitle {
            color: #999999;
            font-size: 0.16rem;
            margin: 0.2rem 0;
        }
    }
    .panoramaTitle {
        text-align: center;
        font-size: 0.28rem;
        margin-top: 0.6rem;
        color: #333;
        border-radius: 0.2rem;
        display: inline-block;
        padding: 0.1rem 0.2rem;
    }
    .panorama {
        // text-align: center;
        width: 70%;
        margin: 0 auto;
        // margin-top: 0.6rem;
        >div{
            margin-top: 0.6rem;
        }
        .title{
            font-size: 0.3rem;
            font-weight: 400;
            color: #333333;
            margin-bottom: 0.6rem;
        }
        .subcontent{
            padding-left: 0.4rem;
            text-indent: 2em;
            font-size: 0.2rem;
            line-height: 0.4rem;
            font-weight: 400;
            color: #808080;
        }
        .largeImage img{
            width: 100%;
        }
            .functions{
                display: flex;
                justify-content: space-between;
                 .content{
                 box-sizing: border-box;
                  width: 20%;
                  height: 2rem;
                  padding: 0.5rem 0.3rem;
                  color: #fff;
                  text-align: left;
                 }
                 p:nth-child(1){
                 font-size: 0.18rem;
                 margin-bottom: 0.3rem;
                 }
                 p:nth-child(2){
                 font-size: 0.24rem;
                 font-weight: 400;
                 }
              }
           .cloudPlatform,.industries,.storage,.dataMark{
               margin-top: 0.6rem;
               .title{
                   text-align: center;
               }
               .content{
                   display: flex;
                   align-items: center;
                //    justify-content: ;
                >div{
                    width: 50%;
                }
               }
           }
           .industries .title{
               padding-left: 0.4rem;
               margin-bottom: 0.4rem;
               text-align: left;
           }
             .storage {
                 background-color: #F5F5F5;
              .subcontent{
                 padding-right: 0.4rem;
                 padding-left: 0;
             }   
             .cards{
                 margin-top: 0.6rem;
                 display: flex;
                 justify-content: space-between;
                 .card{
                     padding: 0.4rem  0.15rem;
                     box-sizing: border-box;
                     width: 24%;
                     background-color: #fff;
                     font-weight: 400;
                     border-radius: 0.1rem;
                     border: 1px solid #EBEBEB;
                     img{
                         display: block;
                         width: 28%;
                         margin:0 auto 0.4rem ;
                     }
                     .cardTitle{
                         text-align: center;
                         font-size: 0.2rem;
                         line-height: 0.4rem;
                         color: #333;
                     }
                     .cardContent{
                         text-align: left;
                         text-indent: 2em;
                         font-size: 0.16rem;
                         line-height: 0.35rem;
                         color: #808080;
                     }
                 }
                 .card:hover{
                     border: 1px solid #0061E3;
                     box-shadow: 0px 6px 20px 0px rgba(169, 169, 169, 0.36);
                 }
             }
             }
             
        .productTab {
            width:100%;
            height: 4.5rem;
            margin: 0.5rem auto auto auto;
            .productChildren {
                //margin-bottom: 0.4rem;
                height: 3rem;
                .left, .right {
                    display: inline-block;
                    width: 20%;
                    height: 100%;
                    background-color: #F7F7F7;
                    border-top: 2px solid #0061E3;
                    text-align: left;
                    position: relative;
                    box-shadow: 0px 3px 8px 0px rgba(187, 187, 187, 0.3);
                    .text {
                        display: inline-block;
                        width: 90%;
                        height: 100%;
                        overflow: hidden;
                        padding: 0.2rem;
                        div {
                            padding: 0.2rem;
                            font-size: 0.16rem;
                            span {
                                padding: 0.1rem;
                                cursor: pointer;
                            }
                            .active {
                                border-bottom: 1px solid #0061E3;
                                color: #0061E3;
                            }
                        }
                    }
                }
                .right {
                    margin-left: 5%;
                }
            }
            .application {
                height: 4rem;
                overflow: hidden;
            }
            .tabs {
                margin-bottom: 0.4rem;
                span {
                    display: inline-block;
                    width: 20%;
                    height: 0.64rem;
                    border-radius: 0.03rem;
                    background: #F7F7F7;
                    cursor: pointer;
                    color: #0061E3;
                    line-height: 0.6rem;
                    font-size: 0.24rem;
                }
                .active {
                    background: #0061E3;
                    color: #FFFFFF;
                }
            }
        }
    }
}
.customerCase {
    .newsList-item {
        &-left{
            width:4.4rem;
            height: 2.8rem;
        }
        &-right {
            width: calc(100% - 4.8rem);
        }
    }
}
.news-flex{
    display: flex;
    justify-content: space-between;
}
.newsList-item{
    height: 3rem;
    padding:0.2rem 0.1rem;
    border-top:1px solid #dedede;
    border-bottom:1px solid #dedede;
    text-align: left;
    &-left{
        width:7.4rem;
        height: 2.8rem;
    }
    &-right{
        width: calc(100% - 7.8rem);
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        .newsItem-title{
            text-align: left;
            font-size: 0.22rem;
            color:#333;
        }
    }
    .newsItem-content{
        font-size: 0.13rem;
        color:#606266;
        line-height: 0.26rem;
        height:1.5rem;
        width:100%;
        overflow:hidden;
        text-overflow:ellipsis;
        display:-webkit-box;
        /* autoprefixer: off */
        -webkit-box-orient:vertical;
        /* autoprefixer: on */
        -webkit-line-clamp:6;

    }
    .newsItem-link{
        width: 100%;
        height:0.4rem;
        &-button{
            width: 0.3rem;
            height:0.3rem;
            float: right;
        }
    }

}

.newsList-item:hover {
    background: #f0f6ff;
    cursor: pointer;
}

</style>
